<template>
  <v-layout :class="['ma-1', card && 'delegate']" align-center v-ripple>
    <v-flex shrink v-if="card" @click.stop="handleSelection">
      <v-checkbox
        :ripple="false"
        hide-details
        class="ma-0 pa-0 ml-2 zg-text-control"
        readonly
        v-model="selected"
      ></v-checkbox>
    </v-flex>
    <DefaultButton
      v-if="!card"
      icon
      small
      class="ma-0 pa-0"
      color="error"
      bgColor="transparent"
      @click.prevent="handleSelection"
    >
      <v-icon small>mdi-trash-can-outline</v-icon>
    </DefaultButton>
    <v-layout
      align-center
      @click.prevent="() => item.selectable && card && handleSelection()"
      class="clickable-text"
    >
      <v-icon
        v-if="!item.selectable && card && !hideOpenIcon"
        small
        class="ml-0 pr-0"
        >{{ openIcon }}</v-icon
      >
      <DefaultSubText
        class="mr-4 ml-2 text-truncate"
        v-html="text"
      ></DefaultSubText>
      <v-icon small class="mr-2" color="primary">{{ favoriteIcon }}</v-icon>
    </v-layout>
  </v-layout>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    item: {},
    open: {
      type: Boolean,
      default: false,
    },
    favorites: {},
    card: {
      type: Boolean,
      default: false,
    },
    hideOpenIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    internalFavorites: {
      get() {
        return this.favorites;
      },
      set(value) {
        this.$emit("update:favorites", value);
      },
    },
    index() {
      return _.findIndex(this.favorites, { id: this.item.id });
    },
    selected() {
      return this.index !== -1;
    },
    text() {
      return `<b>${this.item.code}</b> - ${this.item.text}`;
    },
    openIcon() {
      return this.open ? "mdi-chevron-up" : "mdi-chevron-down";
    },
    favoriteIcon() {
      return this.selected ? "mdi-star-outline" : "far fa-star";
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
  },
  methods: {
    handleSelection() {
      if (this.index === -1) {
        this.internalFavorites.push(this.item);
      } else {
        this.internalFavorites.splice(this.index, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.delegate {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  height: 30px !important;
}
.clickable-text {
  height: 100%;
}
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>